.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.card-shadow {
  -webkit-box-shadow: 1px 4px 5px 3px rgba(218, 228, 230, 0.8);
  -moz-box-shadow: 1px 4px 5px 3px rgba(218, 228, 230, 0.8);
  box-shadow: 1px 4px 5px 3px rgba(218, 228, 230, 0.8);
  // border: 1px solid var(--ion-color-medium-rgb);
}

// General Cards
.card-md-1 {
  width: 50%;
  --background: white;

  @media screen and (max-width: 675px) {
    width: 95%;
  }
}

.card-md-2 {
  width: 70%;
  --background: white;

  @media screen and (max-width: 675px) {
    width: 95%;
  }
}

.card-max {
  width: 95%;
  --background: white;
}

// Starter Form Card
.form-card-1 {
  margin: auto;
  background-color: white !important;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;

  @media screen and (min-width: 768px) {
    width: 650px;
    align-content: center;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    border-radius: 10px;
    background-color: white !important;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    border-radius: 10px;
    background-color: white !important;
  }
}

.form-card-2 {
  // login form card
  margin: auto;
  background-color: white !important;
  border-radius: 10px;
  width: 450px;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

/**Card styling internal content **/
.form-content {
  align-content: center;
  margin: auto;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  @media screen and (min-width: 500px) and (max-width: 768px) {
    width: 80%;
  }

  @media screen and (min-width: 768px) {
    width: 485px;
  }
}

.loginCard {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8em;
  background-color: white !important;
  border-radius: 7px;

  @media screen and (max-width: 1199px) {
    width: 100%;
    height: 100vh;
    margin-top: unset;
    margin: unset !important;
    border-radius: unset !important;
    background-color: white !important;
  }
}

.signInCard {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  background-color: white !important;
  border-radius: 7px;

  @media screen and (max-width: 1199px) {
    width: 100%;
    margin-top: unset;
    margin: unset !important;
    border-radius: unset !important;
    background-color: white !important;
  }
}

.center-card {
  margin: auto;
}

.details-list {
  width: 80%;
  margin: auto;
  height: auto;

  @media screen and (max-width: 767px) {
    width: calc(100% - 10px);
  }
}

.details-tab {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  margin-right: 0.2em;
  margin-left: 0.2em;
  z-index: 2;
}

.details-list-card {
  margin-top: 0;
  margin-right: 0.3em;
  margin-left: 0.3em;
}

.subtitle-message {
  margin-left: 2.5em;
  margin-top: 0.5em;
}

.application-card-details {
  --background: var(--ion-color-light-orange-5);
  border: 1px solid var(--ion-color-light-orange-1);
  box-shadow: none;
  width: 100%;
  margin: 0.5em;

  ion-card-content {
    padding-top: 0px;
  }

  ion-item {
    --background: var(--ion-color-light-orange-5);
    max-height: 2em;

    ion-label {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  ion-list {
    --background: var(--ion-color-light-orange-5);
  }
}
