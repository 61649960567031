.modal-header {
    --background: var(--ion-color-light-blue-2) !important;
    --border-color: transparent !important;
    box-shadow:4px 3px px -2px rgba(128, 172, 255, 0.404), 4px 2px 2px 4px rgba(128, 172, 255,.404), 4px 1px 5px 4px rgba(128, 172, 255,.404);
  }
  
  .modal-title {
    --color: var(--ion-color-dark-blue);
    padding-left: 4em;
    font-family: "Avenir Lt Std 85 Heavy";
    font-size: 1.3em;
  }