* {
  font-size: 16px;
}

.section-title {
  font-size: 1.25em;
  font-family: 'Avenir LT Std 65 Medium';

  @media screen and (max-width: 992px) {
    font-size: 1em;
  }
}

.page-title {
  font-size: 1.45rem;

  @media screen and (max-width: 992px) {
    font-size: 1.25rem;
  }
}

.global-nav-bar {
  font-size: 1.25em;
  font-family: 'Avenir LT Std 65 Medium' !important;
  background-color: white !important;
  background: white !important;
  letter-spacing: normal;
  display: inline-flex;

  ion-icon {
    font-size: 0.8rm;
  }
}

.warning-text {
  color: var(--ion-color-danger);
}

.bold {
  font-family: 'Avenir LT Std 85 Heavy' !important;
}

.application-readonly {
  --background: var(--ion-color-light-teal-5);
}

.title-padding {
  padding: 0.5em;
}

.small-text {
  font-size: 0.9em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;

  span {
    font-size: 0.9em;
  }
}

.x-small-text {
  font-size: 0.85em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;

  span {
    font-size: 0.85em;
  }
}

.sub-title {
  font-size: 1.25em;
  font-family: 'Avenir LT Std 95 Black';

  @media screen and (max-width: 992px) {
    font-size: 1em;
  }
}

.add-tags-button {
  height: 1.3em;
  width: 2em;
  --padding-start: 0;
  --padding-end: 0;
  margin-bottom: 0.8em;
}

.anchor-link {
  cursor: pointer;
}

.settings-font-label {
  font-size: 1.35rem !important;
}

.input-label-text {
  font-size: 0.81em;
}

.card-title-verify {
  font-size: 16px;
  color: var(--ion-color-dark-gray);
}

.icon-lg {
  color: var(--ion-color-dark-teal-4);
  font-size: 50px;
}

.missing-color {
  color: var(--ion-color-text-teal);
}

.pending-color {
  color: var(--ion-color-medium-orange-1);
}
.accepted-color {
  color: var(--ion-color-teal);
}

.rejected-color {
  color: var(--ion-color-red);
}

.document-reviewed-text {
  color: var(--ion-color-dark-gray);
}

.document-reviewed-date {
  text-decoration: underline;
}

.small-line-hight {
  line-height: 0.97;
}

.country-phone-label {
  display: block;
  font-family: AvenirLTStd-Medium, 'Avenir LT Std 65 Light', 'Avenir LT Std 55 Roman', 'Avenir LT Std', sans-serif;
  /* font-size: 0.75rem; */
  /* margin-top: -1rem; */
  padding: 0rem 0px 1.5rem;
  z-index: 0;
}
