/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

// Brand Colors
$brand1: #e86e00;
$brand2: #11b3c4;
$brand3: #36496d;
$brand4: #787878;
// Semantic Colors
$white: #ffffff;
$black: #000000;
$charcoal: #333333;
$red: #d64541;
$reddark: #d9001b;
$green: #2e8b57;
$gray: #d7d7d7;
$graylight: #f2f2f2;

// Dark Teal
$tealdark4: #011719;
$tealdark3: #062e33;
$tealdark2: #06565e;
$tealdark1: #0b6d78;
// Medium Teal
$tealmedium4: #52b6ca;
$tealmedium3: #2595a1;
$tealmedium2: #72b9c1;
$tealmedium1: #9dc0c4;
//Neon and Bright Teal
$neonteal: #15e8ff;
$tealbright: #c0f8ff;
//Gray Teal
$tealgray5: #c2cbcc;
$tealgray4: #dae4e5;
$tealgray3: #e6f1f2;
$tealgray2: #f6fafb;
$tealgray1: #f4fdff;
// Light Teal
$teallight6: #b8e0e6;
$teallight5: #ccfaff;
$teallight4: #e5fcff;
$teallight3: #e6fcff;
$teallight2: #f2feff;
$teallight1: #fafeff;

//Dark Orange
$orangedark3: #332e2a;
$orangedark2: #7f5d40;
$orangedark1: #d94415;
//Medium Orange
$orangemedium3: #e86c00;
$orangemedium2: #dd7515;
$orangemedium1: #f7a253;

// Gray Orange
$orangegray4: #999591;
$orangegray3: #ccc1b8;
$orangegray2: #e8cfba;
$orangegray1: #f2ece6;
//Light Orange
$orangelight5: #ecb98b;
$orangelight4: #e8aa74;
$orangelight3: #ffd6b2;
$orangelight2: #ffe4cc;
$orangelight1: #fffbf9;

// Dark Blue
$bluedark2: #1d283b;
$bluedark1: #6b7b99;
// Medium Blue
$bluemedium3: #32a3e3;
$bluemedium2: #869abf;
$bluemedium1: #688bcf;
$bluemedium0: #80acff;
// Bright Blue
$bluebright: #80acff;
// Light Blue
$bluelight4: #e6eeff;
$bluelight3: #f2f7ff;
$bluelight2: #f8faff;
$bluelight1: #fafcff;

//Custom colors for tags

$green3: #62be50;
$green2: #78bb5e;
$green1: #7de49f;

$gold: #eed648;

$pink1: #ef81c7;

$purple1: #ba7dda;

$silver: #b4bac4;

$redlight: #fed8d6;

//Custom colors for labels

$labelgreen: #08565e;

$linkgray: #555555;

//scroll gray background

$mediumgray: #a5a5a5;

$modalheader: #e9f1f2;

$darkgray: #808080;

$brightred: #ec3334;

$veryreddark: #892c2e;

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Angular Material
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// angular calendar
@import '../node_modules/angular-calendar/css/angular-calendar.css';

// Ag Grid themes
// @import "~@ag-grid-community/all-modules/dist/styles/ag-grid.css";
// @import "~@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
// @import "~@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.css';

// Ag Quill/wysiwyg
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

// Layouts
@import 'theme/layout/card.scss';
@import 'theme/layout/grid.scss';
@import 'theme/colors.scss';
@import 'theme/copy.scss';
@import 'theme/typography.scss';
@import 'theme/layout/button.scss';
@import 'theme/layout/application.scss';
@import 'theme/layout/modal.scss';

// bootstrap color variables override
// $form-check-input-bg:
// $form-check-input-border

@import './app/app.scss';

$form-check-input-indeterminate-bg-color: $orangelight2;
$form-check-input-indeterminate-border-color: $orangelight3;
$form-check-input-focus-box-shadow: 0 0 0 0.25rem rgb(253 99 13 / 25%);
$form-switch-focus-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f7a253'/%3e%3c/svg%3e");
$form-check-input-active-filter: $orangemedium2;
$form-check-input-focus-border: $orangemedium1;
$form-check-input-checked-bg-color: $orangemedium3;
$form-check-input-checked-border-color: $orangemedium1;

// Bootstrap

// scss-docs-start import-stack
// Configuration
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_maps';
@import 'bootstrap/scss/_mixins';
@import 'bootstrap/scss/_utilities';

// Layout & components
@import 'bootstrap/scss/_root';
@import 'bootstrap/scss/_reboot';
@import 'bootstrap/scss/_type';
@import 'bootstrap/scss/_images';
@import 'bootstrap/scss/_containers';
@import 'bootstrap/scss/_grid';
@import 'bootstrap/scss/_tables';
// @import "bootstrap/scss/_forms";
@import 'bootstrap/scss/_buttons';
// @import "bootstrap/scss/_transitions";
// @import "bootstrap/scss/_dropdown";
@import 'bootstrap/scss/_button-group';
@import 'bootstrap/scss/_nav';
@import 'bootstrap/scss/_navbar';
@import 'bootstrap/scss/_card';
@import 'bootstrap/scss/_accordion';
// @import "bootstrap/scss/_breadcrumb";
// @import "bootstrap/scss/_pagination";
// @import "bootstrap/scss/_badge";
// @import "bootstrap/scss/_alert";
// @import "bootstrap/scss/_progress";
// @import "bootstrap/scss/_list-group";
// @import "bootstrap/scss/_close";
// @import "bootstrap/scss/_toasts";
// @import "bootstrap/scss/_modal";
// @import "bootstrap/scss/_tooltip";
// @import "bootstrap/scss/_popover";
// @import "bootstrap/scss/_carousel";
// @import "bootstrap/scss/_spinners";
// @import "bootstrap/scss/_offcanvas";
// @import "bootstrap/scss/_placeholders";

// Helpers
@import 'bootstrap/scss/_helpers';

// Utilities
@import 'bootstrap/scss/bootstrap-utilities';
// scss-docs-end import-stack

.relative {
  position: relative;
}

// AG Grid CSS Overrides
.ag-theme-balham .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,
.ag-theme-balham .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
  border-left: 5px solid var(--ion-color-secondary) !important;
}
.ag-theme-balham .highlight-row {
  background-color: #fff3a1 !important;
}
// end

// Ionic Components Overrides
.month-dropdown {
  --width: 25em;
}

.select-dropdown {
  --width: 25em;
}

.document-viewer-modal {
  --width: 80vw !important;
  --height: 80vh !important;
}

.multi-document-viewer-modal {
  --width: 95vw !important;
  --height: 80vh !important;
}

.ag-charts-modal {
  --width: 80vw !important;
  --height: 80vh !important;
}

.application-calendar-modal {
  --height: 662px;

  .modal-wrapper.sc-ion-modal-md {
    border-radius: 9px;
  }
}

.popover-setting-filter-actions {
  right: 0 !important;
  --width: 135px;
  --max-width: 135px;

  ion-item {
    margin-bottom: 10px;
    margin-top: 0px;
    height: 30px;
    max-height: 30px;

    ion-label {
      cursor: pointer;

      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }
}

.popover-search-key-filter-actions {
  right: 0 !important;
  --width: 135px;
  --max-width: 135px;
  overflow: scroll;

  ion-item {
    font-size: 14px;
    font: 'Avenir LT Std  35 Light';

    color: #333333;

    fill: #ffffff;
    font-family: 'Avenir LT Std 55 Roman';
    margin-bottom: 10px;
    margin-top: 0px;
    height: 30px;
    max-height: 30px;

    ion-label {
      cursor: pointer;

      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }
}

.popover-search-value-filter-actions {
  right: 0 !important;
  --width: 135px;
  --max-width: 135px;
  overflow: scroll;

  ion-item {
    font-size: 14px;
    font: 'Avenir LT Std  35 Light';

    color: #333333;

    fill: #ffffff;
    font-family: 'Avenir LT Std 55 Roman';
    margin-bottom: 10px;
    margin-top: 0px;
    height: 30px;
    max-height: 30px;

    ion-label {
      cursor: pointer;

      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }
}

.popover-setting-actions {
  right: 0 !important;
  --width: 175px;
  --max-width: 175px;

  ion-item {
    margin-bottom: 10px;
    margin-top: 0px;
    height: 30px;
    max-height: 30px;

    ion-label {
      cursor: pointer;

      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }
}

.popover-setting-filter-actions-payments {
  right: 0 !important;
  --width: 160px !important;
  --min-width: 160px !important;

  ion-item {
    // --width:160px !important;
    margin-bottom: 10px;
    margin-top: 0px;
    height: 30px;
    max-height: 30px;

    ion-label {
      cursor: pointer;

      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }
}

.popover-program-bundle-selection-actions {
  --width: 275px;
}

.popover-setting-manager-actions {
  right: 0 !important;
  margin: 0;
  padding: 0;
  --width: 180px;
  --max-width: 180px;

  ion-item {
    margin-bottom: 0px;
    margin-top: 0px;
    height: 48px;
    max-height: 48px;
    text-align: center;
    --background-hover: var(--ion-color-light-blue-1);

    ion-label {
      margin: 0;
      font-size: 14px !important;
      line-height: 18px !important;
      color: var(--ion-color-secondary) !important;
      cursor: pointer;
    }
  }
}

.popover-setting-log-actions {
  position: absolute;
  left: 0;
  bottom: 0;
  --width: 220px !important;
  --max-width: 250px !important;
}

.popover-setting-date-actions {
  position: absolute;
  right: 0;
  bottom: 0;
  --width: 420px !important;
  --max-width: 420px !important;
}

.popover-setting-admin-actions {
  position: absolute;
  left: 0;
  bottom: 0;
  --width: 350px !important;
  --max-width: 400px !important;
  --height: 300px;
}

.assignmentDropdown {
  --width: 325px;
}

.program-create-modal {
  --height: 415px;
}

.popover-document-selector {
  right: 0 !important;

  ion-item {
    cursor: pointer;

    &:hover {
      color: var(--ion-color-primary);
    }
  }
}

.ag-grid-100-height {
  position: absolute;
  height: 100% !important;
  padding-top: 180px;
  right: 0;
  left: 0;
  bottom: 0;
}

.ag-grid-100-height-no-padding {
  position: absolute;
  height: 100% !important;
  right: 0;
  left: 0;
  bottom: 0;
}

.ag-grid-padding {
  padding-top: 302px !important;
}

.side-menu-pane {
  margin-top: 100px;

  .side-menu-panel {
    background: #f9fbfa;

    .modal {
      font-size: 0.75em;

      ion-label {
        white-space: pre-line;
      }
    }
  }
}

.btns-toolbar {
  margin-top: 70px;
}

.save-btn-toolbar {
  box-shadow: 0 6px 4px -6px black;
  padding-right: 15px;
}

.toolbar-with-breadcrumb {
  display: block;
  box-shadow: 0 6px 4px -6px black;
  padding: 30px 0 0 16px;
  margin: 0 0 6px 0;
  height: 90px;

  .amo-breadcrumb {
    float: left;
  }

  .status {
    float: right;
  }
}

.fixed-side-header {
  position: fixed;
  width: 100%;
  box-shadow: 0 5px 6px -6px black;
}

.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 18% !important;
}

.left-menu {
  margin-top: 6px;
  width: 100%;
  overflow-x: auto;

  div {
    cursor: pointer;
    text-align: center;
    height: 54px;
    line-height: 54px;
    color: #616161;

    &:hover {
      background-color: #f6f6f6;
      color: #1b283a;
    }

    &.router-link-active {
      background-color: #1b283a;
      color: #ffffff;
      font-weight: 700;
    }

    &.router-link-active:hover {
      background-color: #1b283a;
      color: #faebeb;
    }
  }
}

.background-teal-linear-gradient {
  background: linear-gradient(to top, rgb(255, 251, 249), rgb(204, 250, 255));
  --background: linear-gradient(to top, rgb(255, 251, 249), rgb(204, 250, 255));
}

.background-light-teal {
  background: var(--ion-color-light-teal);
  --background: var(--ion-color-light-teal);
}

.align-right {
  text-align: right;
}

// .form-check-input {
//   color: #52b6ca;
//   // color: #E86C00;
// }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.provisional-container {
  margin-right: 20%;
  margin-top: 3em;
  margin-left: 2em;
}

.col-middle-space {
  ion-col {
    &:nth-of-type(odd) {
      margin-right: 2vw;
    }
    &:nth-of-type(even) {
      margin-left: 2vw;
    }
  }
}

.order-selection-modal {
  --width: 260px;
}

.mat-menu-panel {
  background-color: white;

  button {
    background-color: white;
  }
  a {
    color: black;
  }
}
