ion-button {
    text-transform: none;
    font-family: 'Avenir LT Std 65 Medium';

    i {
        margin-bottom: 2px;
    }
}

ion-button {
    text-transform: none;
    font-family: 'Avenir LT Std 65 Medium';

    i {
        margin-bottom: 2px;
    }
}

.header-button-row {
    display: inline-flex;
    float: right;
}

.half-button {
    max-height: 48px;
    min-width: 148px;
    font-size: 0.9em;
    font-family: 'Avenir LT Std 65 Medium';
}

.small-button {
    max-height: 35px;
    min-width: 100px;
    padding: 0.1em;
    font-size: 0.875em;
    font-family: 'Avenir LT Std 65 Medium';
}

.dashboard-button {
    --color: var(--ion-color-teal) !important;
    --padding-top: 1.5em;
    text-decoration: underline;
    // border-bottom: 1px solid var(--ion-color-teal)
}

.custom-document-button-incomplete {
    height: 3.5em;
    width: 14em;
    --opacity: 1 !important;
    --border-radius: 10px;
    --color: white;
    --background: var(--ion-color-teal);
    --background-focused: var(--ion-color-secondary-shade);
    // --background-activated: var(--ion-color-secondary-tint);
    --background-hover:var(--ion-color-teal) !important;
    // --border-color: var(--ion-color-secondary);
    --border-width: 2px;
    margin-bottom: 1em;

    i {
        position: absolute;
        left: 8%;
    }

    ion-text {
        width: 80%;
    }
}

.custom-document-button-complete {
    height: 3.5em;
    width: 14em;
    --border-radius: 10px;
    --color: white;
    --background: var(--ion-color-dark-teal-1) !important;
    --background-focused: var(--ion-color-secondary-shade) !important;
    --background-hover: var(--ion-color-dark-teal-2) !important;
    --background-activated: var(--ion-color-secondary-tint) !important;
    --border-color: var(--ion-color-secondary) !important;
    --border-width: 2px;
    margin-bottom: 1em;

    i {
        position: absolute;
        left: 8%;
    }

    ion-text {
        width: 80%;
    }
}

.blocked-button {
    height: 3.5em;
    width: 14em;
    --opacity: 1 !important;
    --border-radius: 10px;
    --color: white;
    --background: var(--ion-color-gray-teal-5);
    --background-hover:var(--ion-color-gray-teal-5) !important;
    --border-width: 2px;
    margin-bottom: 1em;

    i {
        position: absolute;
        left: 8%;
    }

    ion-text {
        width: 80%;
    }
}

.icon-margin-end {
    margin-right: 0.3em;
}


.save-button-placement {
    position: absolute;
    bottom: 0;
    right: 0;
}

.btn-link-text {
    text-decoration: underline;
    font-size: 0.875em;
    line-height: 1.5 !important;
    font-family: 'Avenir Lt Std 85 Heavy';
    width: 225px;
    min-height: 54px;
}


.application-segment-button {
    --background-checked: var(--ion-color-white);
    --color-checked: var(--ion-color-primary);
    padding-right:2em;
    padding-left: 2em;
    text-transform: capitalize;

    .segment-label {
        text-transform: capitalize;

        @media screen and (max-width: 576px) {
            font-size: 0.8em;
        }

        @media screen and (max-width: 320px) {
            font-size: 0.7em;
        }
    }

    @media screen and (max-width: 1024px) {
        padding-right: 0em;
        padding-left: 0em;
        width: 50% !important;
    }
}

.dashboard-icons {
    color: var(--ion-color-primary);
    font-size: 2em;
}

.transparent-button-upload {
    --background: var(--ion-color-white);
    opacity: 0.9;
    --color: var(--ion-color-text-teal);
    font-size: .8em;
    --padding-start: 0.3em;
    --padding-end: 0.3em;
    --background-hover: #b3afaf
}

.strong-button {
    font-size: 1.1rem !important;

}