form[autocomplete='off'] input[type='text'] {
  background-position: 150% 50% !important;
}

.requirementAlert {
  --max-width: 400px;
}

.intl-phone-field {
  border: none;
  font-size: 1rem;
  color: black !important;
  padding: 0.3rem;
  width: 100%;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

.text-center {
  text-align: center;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

.background-grey {
  background-color: rgb(243, 243, 243);
  --background: rgb(243, 243, 243);
}

.background-grey-2 {
  background-color: var(--ion-color-semantic-light-gray);
  --background: var(--ion-color-semantic-light-gray);
}

.permissions-selection {
  --min-width: 47vw;
}

.details-page-component {
  background: var(--ion-color-white);
  padding-bottom: 3em;
  overflow-y: auto;
  margin-top: 3em;
  margin-left: 1rem;
  margin-bottom: 3.5em;
}

.details-page-component-no-top {
  background: var(--ion-color-white);
  padding-bottom: 3em;
  overflow-y: auto;
  margin-bottom: 3.5em;
}

.application-dropdown-list {
  --width: 517px;
  --max-width: 517px;
}

.application-actions-lists {
  --width: 290px !important;
}

.requirements-status-list {
  --width: 200px !important;
}

.institution-action-lists {
  --width: 225px !important;
}

.help-lists {
  --min-width: 300px !important;
}

.anchor-input {
  display: flex;
  align-items: center;
}

.anchor-input-v2 {
  position: absolute;
  top: 39px;
  right: 4%;
  z-index: 1000;
}

.anchor-input-v3 {
  position: absolute;
  top: 42px;
  right: 12%;
  z-index: 1000;
}

.hover-link:hover {
  color: var(--ion-color-teal);
}

@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}

.quick-summary-component {
  margin-top: 7em;
  padding-bottom: 3em;
  overflow-y: auto;

  .header-info {
    text-align: center;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    color: var(--ion-color-dark-shade);
  }

  .logs {
    margin-top: 20px;

    .text-log {
      color: var(--ion-color-dark-shade);
      margin: 5px 0;

      ion-label {
        font-weight: 500;
      }

      .text-date {
        font-weight: 800;
        display: block;
      }
    }
  }

  .common-ion-badge {
    font-weight: 100;

    span {
      font-weight: 800;
      margin-left: 4px;
    }
  }

  .text-summary {
    color: var(--ion-color-dark-shade);
    margin: 5px 0;

    ion-label {
      &:first-child {
        font-weight: 800;
      }

      &:nth-child(2) {
        font-weight: 500;
      }
    }

    .featured {
      color: var(--ion-color-primary);
      font-weight: 800;

      &::before {
        content: '● ';
        font-size: 20px;
        margin-left: 5px;
      }
    }

    ion-badge {
      @extend .common-ion-badge;
      background: var(--ion-color-mid-blue-1);
    }
  }

  .link {
    color: var(--ion-color-teal);
    text-decoration: underline;
    margin: 15px 0;
    display: block;
    cursor: pointer;
  }

  @mixin badge-color($color) {
    ion-badge {
      background: $color;
    }
  }

  .user-badges {
    margin-top: 25px;

    .text-summary {
      ion-badge {
        @extend .common-ion-badge;
      }

      &:first-child {
        @include badge-color(#ff9f19);
      }

      &:nth-child(2) {
        @include badge-color(#eb5a46);
      }

      &:nth-child(3) {
        @include badge-color(#00c2e1);
      }

      &:nth-child(4) {
        @include badge-color(#ff78cb);
      }

      &:nth-child(5) {
        @include badge-color(#c378e0);
      }

      &:nth-child(6) {
        @include badge-color(#52e898);
      }

      &:nth-child(7) {
        @include badge-color(#52e898);
      }

      &:nth-child(8) {
        @include badge-color(#344563);
      }
    }
  }
}

.hold-select {
  --min-width: 415px;
}

.actions-btn {
  --background: var(--ion-color-teal);
  --background-hover: var(--ion-color-mid-teal-3) !important;
  --color: var(--ion-color-white);
  border: none;
  margin: 0 auto 0 auto;
}

.mat-expansion-panel {
  border-radius: 0 !important;
  margin-bottom: 2px !important;

  .mat-expansion-panel-spacing {
    margin: 6px 0 !important;
  }

  .mat-expansion-panel-header {
    padding: 0 17px;
  }

  .mat-expansion-panel-body {
    padding: 0 5px 5px;
  }
}

.popover-permission-info {
  --min-width: 350px !important;

  p {
    font-size: 14px;
  }
}

.limit-select-width {
  width: 6.5em;
}

.grid-limit-dropdown {
  --min-width: 125px !important;
}

.underline {
  text-decoration: underline !important;
}

.exposure-popover-width {
  --min-width: 300px;
}

.auth-update-modal {
  --max-width: 420px;
  --max-height: 357px;
}

.auth-update-phone-modal {
  --max-width: 420px;
  --max-height: 490px;
}

.auth-ps-update-modal {
  --max-width: 420px;
}

.modal-google-map-geo-location {
  --height: 548px;
  --max-width: 600px;

  @media (max-width: 768px) {
    --max-width: 100%;
  }
}

.provisional-blocker-modal {
  backdrop-filter: blur(6px);
  --max-width: 600px;
  --max-height: 400px;
  --background: white;
  height: 93vh;
  margin-top: 7vh;
  background-color: transparent !important;
}

.clear-date-icon {
  position: absolute;
  float: right;
  top: 10px;
  right: 1%;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-form-field-infix {
  padding: 0em !important;
  border-top: 0.5em solid transparent;
}

.doc-sub-title {
  color: var(--ion-color-light-contrast);
  font-size: 16px;
  padding-left: 12px;
}

.btns-toolbar {
  padding-right: 2em;
}

.document-table-label {
  font-size: 14px;
  color: var(--ion-color-dark-gray);
}

.btn-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 1.5em 1.5em 1.5em;
}

.sup-btn {
  --border-radius: 2px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 8px;
  --padding-end: 8px;
  --box-shadow: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
}

// .side-menu-item{
//   --background: #FFF;
//   &:hover{
//     --color:#FFF;
//     --background: rgb(54, 73, 109);
//   }
// }

.menu-list {
  margin-top: 6px;
  width: 100%;
  overflow-x: auto;

  .side-menu-item,
  div {
    cursor: pointer;
    text-align: center;
    height: 54px;
    line-height: 54px;
    color: #616161;

    &:hover {
      background-color: #f6f6f6;
      color: #1b283a;
    }

    &.router-link-active {
      background-color: #1b283a;
      color: #ffffff;
      font-weight: 700;
    }

    &.router-link-active:hover {
      background-color: #1b283a;
      color: #faebeb;
    }
  }
}

.default-btn {
  width: auto;
  min-width: 9em;
  --border-radius: 0px;
  font-size: 0.9em;
  height: 2.3em;
  cursor: pointer;
}

.user-warning {
  --background: rgb(255, 255, 255);
  // font-family: 'Courier New', Courier, monospace;
  text-align: center;

  .alert-wrapper {
    .alert-head {
      h2 {
        text-align: center !important;

        &::after {
          content: ' ';
        }
      }
    }

    .alert-message {
      .custom-icon-circle-outline {
        width: 5rem;
        height: 5rem;
        color: #11b3c4;
      }
    }

    .alert-button-group {
      display: flex;
      justify-content: space-around;
      padding-bottom: 2.2rem;

      .user-warning-no {
        border: 1px solid #11b3c4;
        background: #ffffff;
        width: 36%;
        height: 2.2rem;
        border-radius: 4px;
        text-transform: capitalize;

        span {
          color: #11b3c4;
          justify-content: center;
        }
      }

      .user-warning-yes {
        border: #11b3c4;
        background: #11b3c4;
        color: #fff;
        width: 35%;
        height: 2.2rem;
        border-radius: 4px;
        text-transform: capitalize;

        span {
          color: #fff;
          justify-content: center;
        }
      }
    }
  }
}

.status-btn {
  width: 135px;
  border: 2px solid;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  font-size: 0.85em;
  padding: 2px 3px 2px 3px;
  font-weight: 600;
  font-family: 'ArialMT', 'Arial', sans-serif;
  border-radius: 3px;
  box-shadow: 1px 2px 5px rgba(170, 170, 170, 0.34901960784313724);
  background-color: var(--ion-color-white);

  fa-icon {
    font-size: 0.9em;
  }
}

.pending-status-btn {
  color: var(--ion-color-medium-orange-1);
}

.accepted-status-btn {
  color: var(--ion-color-teal);
  background-color: rgba(17, 179, 196, 0.1);
}

.rejected-status-btn {
  color: var(--ion-color-red);
}

.requirement-row {
  display: inline-flex;

  .clear-circle {
    size: 13;
    margin-right: 4px;
    color: #333333;
  }

  .clear-circle:hover {
    color: #d94415;
  }
}

.amoLogo {
  margin: 0px auto;
  width: 97px;
  height: 40px;
  padding-top: 10px;
  // padding-left: 20px;
  display: inline-flex;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 32px;
    // padding-left: 2em;

    padding-top: 0px;
  }
}

.md .amoLogoOffset {
  padding-right: 3em;
  padding-top: 0.5em;
}

.mobile-header ion-header {
  @media screen and (min-width: 991px) {
    display: flex;
  }
}

.custom-school-request-button {
  color: var(--ion-color-primary);
}

.order-custom-seat-modal {
  border-width: 0px;
  --max-width: 460px;
  --max-height: 260px;
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.block-remove-reason-modal {
  --max-width: 450px;
  --max-height: 310px;
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-duration-modal {
  --width: 800px;
  --max-width: 800px;
  --max-height: 460px;
  --background: white;
  background-color: transparent !important;
}

.input-geo-opener {
  position: absolute;
  right: 4%; //4%;
  bottom: 9%;
  @media screen and (max-width: 960px) {
    right: 4%; //6%;
  }
  z-index: 10000000;
  cursor: pointer;
  color: var(--ion-color-teal);
}

.manager-profile-block-modal {
  border-width: 0px;
  --max-width: 430px;
  --max-height: 450px;
  --border-radius: 9px;
}

.fos-student-type-confirmation-modal {
  --max-height: 260px;
}

.material-input {
  -webkit-box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
  -moz-box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
  box-shadow: 1px 3px 5px 3px rgba(218, 228, 229, 0.2);
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  margin-top: 0.2em;
  width: 99%;
}

.host-edit-toast {
  --max-height: 500px;
}

::ng-deep {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: red !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: red !important;
  }
}

.search-dropdown {
  display: block;
  // matches the size of padding on react component labels
  margin-top: 1.25rem;
}

ion-modal.order-capacity-warning-modal {
  --width: 600px;
  --max-width: 600px;
  --max-height: 500px;
  --background: white;
}
